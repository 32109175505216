import React, { useState, useEffect } from 'react';
import ColourHelper from '../../../helpers/ColourHelper';
import GuidHelper from '../../../helpers/GuidHelper';
import { ICategoryGroup, IEvent } from '../../../interfaces/IEvent';
import SVGTicket from '../../../svg/SVGTicket';
import Toolbar from '../toolbar/Toolbar';
import MerchandiseProduct from './MerchandiseProduct';
import linq from 'linq';
import { ITicketCategory } from '../../../interfaces/ITicketCategory';
import SVGMerchandise from '../../../svg/SVGMerchandise';

export interface IProps {
  event: IEvent;
  onEventUpdated: (e: IEvent) => void;
  globalOptions: JSX.Element;
}

const MerchandiseSection: React.FC<IProps> = (props) => {
  const { event, onEventUpdated } = props;
  const [dragOver, setDragOver] = useState<ICategoryGroup>(null);
  const [dragging, setDragging] = useState<ICategoryGroup>(null);
  const [draggingCategory, setDraggingCategory] = useState<ITicketCategory>(null);
  const disableDrag = false;
  const [draggedIndex, setDraggedIndex] = useState(null);
  const [showColourPicker, setShowColourPicker] = useState(false);

  const getItems = () => {
    return linq
      .from(event.UnallocatedCategoryGroups)
      .where((e) => e.Merchandise)
      .orderBy((s) => s.Index)
      .toArray();
  };

  const resetIndexes = () => {
    getItems().forEach((i, index) => (i.Index = index));
    props.onEventUpdated({ ...event });
  };

  useEffect(() => {
    resetIndexes();
  }, []);

  const handleDragStart = (index, group: ICategoryGroup) => (e) => {
    setDragging(group);
    setDragOver(null);
    setDraggedIndex(index);
    e.dataTransfer.effectAllowed = 'move';
    e.dataTransfer.setData('text/html', null);
  };

  const handleDragOver = (index, group: ICategoryGroup) => (e) => {
    e.preventDefault();
    if (draggedIndex !== null && draggedIndex !== index) {
      setDragOver(group);
    }
  };

  const handleDragLeave = (e) => {
    e.target.classList.remove('drag-over');
    setDragOver(null);
  };

  const handleDrop = (e) => {
    if (!dragging || !dragOver) return;

    e.preventDefault();
    e.target.classList.remove('drag-over');

    if (dragOver.Index != dragging.Index) {
      // dragOver.ChangesMade = true;
      // dragging.ChangesMade = true;

      if (dragOver.Index > dragging.Index) {
        dragging.Index = dragOver.Index + 0.5;
      } else if (dragOver.Index < dragging.Index) {
        dragging.Index = dragOver.Index - 0.5;
      }

      const newList = getItems();
      newList.forEach((sp, index) => {
        sp.Index = index;
      });
      event.UnallocatedCategoryGroups = newList;
      props.onEventUpdated({ ...event });
    }

    setDragOver(null);
    setDraggedIndex(null);
    setDragging(null);
  };

  const addNew = () => {
    event.Dates.forEach((ed) => {
      if (ed) ed.ChangeMade = true;
    });

    event.UnallocatedCategoryGroups.push({
      Id: 0,
      Merchandise: true,
      Quantity: 1,
      SelectedEventDates: event.Dates.map((d) => d.Guid),
      HasOrders: false,
      Guid: GuidHelper.new(),
      Index: event.UnallocatedCategoryGroups.length,
      Categories: [
        {
          Index: 0,
          Id: 0,
          People: 1,
          Description: '',
          Name: '',
          HasOrders: false,
          PriceAsString: '',
          Colour: ColourHelper.getRandomColor(),
          Guid: GuidHelper.new(),
          GeneralAdmission: true,
          PriceAsInt: 0,
        },
      ],
    });

    resetIndexes();
  };

  return (
    <>
      <Toolbar>
        <div className="options">
          {props.globalOptions}{' '}
          <button className="option" onClick={addNew}>
            <label>Add Item</label>
            <div className="icon">
              <SVGMerchandise />
            </div>
          </button>
        </div>
      </Toolbar>

      <div className="info">
        Here’s where you can add those event extras, special items, or merchandise that your customers can order alongside their tickets through Seaty. 🛍️
        <br />
        <br />
        Make sure to clearly describe each item, including any special details. Don't forget to mention any specific terms related to these items in your event description or terms and conditions.
        Just like tickets, all items here are tied to an event date. 🎟️
        <br />
        <br />
        Each item you list will be available for each event date. Anything sold here must be ready for collection at the event, on the specified date. 🗓️
        <br />
        <br />
        If an item is linked to a specific ticket, is an extra that can be purchased with a ticket, or has any special conditions for collection, be sure to clearly state that in the item description,
        event terms, or event description. 📝 Make sure your customers know exactly what they're getting and when they can get it, so they have an awesome experience!
      </div>

      <div className="animated fadeIn">
        <table className={disableDrag ? '' : 'draggable'}>
          <tbody>
            {getItems().map((group: ICategoryGroup, index) => {
              return (
                <tr
                  key={'MerchandiseProductGroup_' + index}
                  draggable={!disableDrag && !showColourPicker}
                  style={{ display: 'table-row' }}
                  onDragStart={disableDrag ? null : handleDragStart(index, group)}
                  onDragOver={disableDrag ? null : handleDragOver(index, group)}
                  onDragLeave={disableDrag ? null : handleDragLeave}
                  onDrop={disableDrag ? null : handleDrop}
                >
                  <td
                    className={
                      disableDrag
                        ? ''
                        : `${dragOver && (dragOver.Id ? dragOver.Id : dragOver.Guid) == (group.Id ? group.Id : group.Guid) ? (dragOver.Index > dragging.Index ? ' place-down' : ' place-up') : ''}`
                    }
                  >
                    <MerchandiseProduct
                      setDraggingCategory={(category) => setDraggingCategory(category)}
                      showColourPicker={(value) => setShowColourPicker(value)}
                      handleChange={() => onEventUpdated({ ...event })}
                      disableDrag={false}
                      onAddCategoryToGroup={() => {
                        var color = ColourHelper.getRandomColor();
                        group.Categories.push({
                          Id: 0,
                          People: 1,
                          Description: '',
                          Name: '',
                          HasOrders: false,
                          PriceAsString: '',
                          Colour: color,
                          Guid: GuidHelper.new(),
                          GeneralAdmission: true,
                          PriceAsInt: 0,
                        });

                        onEventUpdated({ ...event });
                      }}
                      onNoCategoriesRemaining={() => {
                        event.UnallocatedCategoryGroups = event.UnallocatedCategoryGroups.filter((x) => x != group);
                        onEventUpdated({ ...event });
                      }}
                      eventDates={event.Dates}
                      index={index}
                      group={group}
                      symbol={event.CurrencySymbol}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        <button className="admin-button" onClick={addNew}>
          Add a item
        </button>
      </div>
    </>
  );
};

export default MerchandiseSection;
