import linq from 'linq';
import { FunctionComponent } from 'react';
import { IEventDate } from '../../interfaces/IEventDate';
import { ITicketCategory } from '../../interfaces/ITicketCategory';
import { ITicketAvailability } from '../../services/TicketService';
import MerchandiseProduct from './MerchandiseProduct';

interface IProps {
  isAdmin: boolean;
  onTicketsChange: (ticketCategory: ITicketCategory, value: number) => void;
  tickets: Array<ITicketCategory>;
  eventDate: IEventDate;
  locks: ITicketAvailability[];
}

const MerchandiseProductGroups: FunctionComponent<IProps> = (props) => {
  var categoryGroups = linq
    .from(props.tickets)
    .where((p) => p.Merchandise)
    .groupBy(
      (o) => o.CategoryGroupId,
      (o) => o,
      (groupId, categories) => {
        return {
          GroupId: groupId,
          GroupIndex: categories && categories.count() > 0 ? categories.first().GroupIndex : 0,
          Categories: categories
            .orderBy((c) => c.CategoryIndex)
            .thenBy((c) => c.Name)
            .where((c) => !c.Hide || props.isAdmin)
            .toArray(),
        };
      },
    )
    .orderBy((g) => g.GroupIndex)
    .thenBy((g) => (g.Categories && g.Categories.length > 0 ? g.Categories[0].Name : 'z'))
    .where((g) => g.Categories.length > 0)
    .toArray();

  return (
    <div>
      {categoryGroups.map((group, groupIndex) => {
        var groupTickets = group.Categories;

        var totalTicketsCount = groupTickets[0].CategoryGroupQuantity;
        var ticketsOrderedOrRequested = linq.from(groupTickets).sum((t) => (t.OrderedTickets + t.PendingRequestedTickets) * t.People);

        var availableTicketsCount = totalTicketsCount - ticketsOrderedOrRequested;
        var locks = props.locks ? props.locks.filter((l) => l.TicketCategoryGroupId == group.GroupId) : [];

        locks.forEach((l) => {
          availableTicketsCount -= l.Quantity;
        });

        var soldOut = availableTicketsCount <= 0;

        var selectedTicketsQuantity = linq.from(groupTickets).sum((ticket) => (ticket.Amount || 0) * ticket.People);
        var remainingTicketsAmount = availableTicketsCount - selectedTicketsQuantity;

        return (
          <div className="general-group" key={`Group_${groupIndex}`}>
            {linq
              .from(groupTickets)
              .toArray()
              .map((ticket, index) => {
                var maxQuantity = Math.floor(remainingTicketsAmount / ticket.People + (ticket.Amount || 0));

                if (!props.isAdmin && maxQuantity > 30) {
                  maxQuantity = 30;
                }

                return (
                  <MerchandiseProduct
                    maxQuantity={maxQuantity}
                    eventDate={props.eventDate}
                    key={'generalAdmissionTicket' + ticket.Id + '_' + index}
                    soldOut={soldOut}
                    value={ticket.Amount || 0}
                    quantitySelectedChanged={(value) => {
                      props.onTicketsChange(ticket, value);
                    }}
                    ticket={ticket}
                  />
                );
              })}

            {!props.isAdmin ? null : (
              <div className="quantity">
                {availableTicketsCount} of {totalTicketsCount} available
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default MerchandiseProductGroups;
