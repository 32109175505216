import React, { useState } from 'react';
import { ChromePicker } from 'react-color';
import InfoInput from '../../../components/InfoInput';
import InfoLabel from '../../../components/InfoLabel';
import Switch from '../../../components/Switch';
import ColourHelper from '../../../helpers/ColourHelper';
import NumberHelper from '../../../helpers/NumberHelper';
import { ITicketCategory } from '../../../interfaces/ITicketCategory';
import SVGDown from '../../../svg/SVGDown';
import SVGTrash from '../../../svg/SVGTrash';
import SVGPaint from '../../../svg/SVGPaint';

interface IProps {
  symbol: string;
  category: ITicketCategory;
  index: number;
  onNameChange: (value: string) => void;
  onAdditionalInformationChange: (value: string) => void;
  onPeopleChange: (value: string) => void;
  onPriceChange: (value: string) => void;
  onColourChange: (value: string) => void;
  onHideChange: (value: boolean) => void;
  onDeleteCategoryClick: (value: ITicketCategory) => void;
  showColourPicker: (show) => void;
}

const MerchandiseProductCategory: React.FC<IProps> = (props) => {
  const [expanded, setExpanded] = useState(false);
  const [price, setPrice] = useState<string>((props.category.PriceAsInt / 100.0).toFixed(2));
  const [priceValid, setPriceValid] = useState<boolean>(NumberHelper.isNumeric((props.category.PriceAsInt / 100.0).toFixed(2)));
  const [showColourPicker, setShowColourPicker] = useState(false);

  var colorHEX = props.category.Colour;
  var colorRGB = ColourHelper.hexToRgb(colorHEX);

  return (
    <div>
      <div className="row" style={{ marginBottom: '0', marginTop: '5px' }}>
        <div className="col-sm-6">
          <InfoInput labelText="Item name" onChange={(value) => props.onNameChange(value)} value={props.category.Name} />
          <InfoInput rows={2} labelText="Item description" onChange={(value) => props.onAdditionalInformationChange(value)} value={props.category.Description} />
        </div>
        <div className="col-sm-2">
          <InfoInput
            symbol={props.symbol}
            onBlur={() => {
              props.onPriceChange(price);
              if (priceValid) setPrice(parseFloat(price).toFixed(2));
            }}
            labelColour={priceValid ? null : 'red'}
            labelText={'Price' + (priceValid ? '' : ' (Invalid)')}
            onChange={(value) => {
              setPriceValid(NumberHelper.isNumeric(value));
              setPrice(value);
              props.onPriceChange(value);
            }}
            value={price}
          />
          <Switch trueLabel="Public" falseLabel="Private" onChange={(value) => props.onHideChange(!value)} checked={!props.category.Hide} />
        </div>
        <div className="col-sm-4 flex_from_right">
          <button style={{ marginLeft: '5px' }} className={`admin-button icon ${expanded ? 'flip' : ''}`} onClick={() => setExpanded(!expanded)}>
            <SVGDown />
          </button>

          <div className="colour-picker-button">
            <button
              style={{ background: 'rgba(' + colorRGB.r + ', ' + colorRGB.g + ', ' + colorRGB.b + ', 1)', marginLeft: '5px' }}
              className={`admin-button colour-picker icon`}
              onClick={() => {
                props.showColourPicker(true);
                setShowColourPicker(true);
              }}
            >
              <SVGPaint />
            </button>

            {showColourPicker && (
              <>
                <div
                  className="click-off"
                  onClick={() => {
                    setShowColourPicker(false);
                    props.showColourPicker(false);
                  }}
                ></div>
                <ChromePicker
                  disableAlpha={true}
                  color={colorHEX}
                  onChange={(value) => {
                    props.onColourChange(value.hex);
                  }}
                />
              </>
            )}
          </div>

          {props.category.HasOrders ? null : (
            <button style={{ marginLeft: '5px' }} className="admin-button icon bad" onClick={() => props.onDeleteCategoryClick(props.category)}>
              <SVGTrash />
            </button>
          )}
        </div>
      </div>

      <div className="row" style={{ display: expanded ? 'block' : 'none', paddingBottom: '30px' }}>
        <div className="col-sm-8">
          <InfoLabel
            text="How many items does this product represent?"
            tooltip="This value represents the quantity of items each of this type of product counts for. So if you have a product with 4 items, set this value to 4 and that value will come off of the quntity available each time someone orders this product."
          />
          <div style={{ maxWidth: '200px' }}>
            <InfoInput labelText="Items" onChange={(value) => props.onPeopleChange(value)} value={props.category.People.toString()} />
          </div>
        </div>
        <div className="col-sm-4">
          {props.category.HasOrders ? (
            <div className="info">
              This item has <strong>existing orders</strong> placed against it and cannot be deleted. If you want to make it unavailable to the public, you can make all items private.
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default MerchandiseProductCategory;
