import linq from 'linq';
import moment from 'moment';
import { FunctionComponent } from 'react';
import CurrencyHelper from '../../../../helpers/CurrencyHelper';
import { IEvent } from '../../../../interfaces/IEvent';
import React, { useEffect, useState } from 'react';

import EventSummary_TicketSalesReportRow from './EventSummary_TicketSalesReportRow';
import UserHelper from '../../../../helpers/UserHelper';
import SVGHelper from '../../../../helpers/SVGHelper';

interface IEventDateSale {
  EventDateId: number;
  EventDateAsString: string;
  SoldTickets: any;
  Discounts: any[];
  PriceAsInt: number;
  TicketsByCategory: any;
}

export interface IProps {
  currency: string;
  eventDateSales: Array<IEventDateSale>;
  event: IEvent;
}

const EventSummary_TicketSalesReport: FunctionComponent<IProps> = (props) => {
  var event = props.event;
  const organisation = event.Organisation;

  var rows = [];
  const hasSeatingPlans = event.UseSeatingPlan && event.SeatingPlans && event.SeatingPlans.length > 0;

  var ticketsEnum = linq.from(props.eventDateSales).orderBy((m) => moment(m.EventDateAsString).unix());

  var totalSoldTickets = ticketsEnum.sum((t) => {
    return (t as any).SoldTickets;
  });

  var totalTickets = ticketsEnum.sum((t) => {
    const eventDate: any = linq.from(event.Dates).firstOrDefault((ed) => ed.Id == t.EventDateId);
    if (!eventDate) return 0;

    const categoryGroupsInDate = linq.from(event.UnallocatedCategoryGroups).where((g: any) => linq.from(g.Categories).any((c: any) => eventDate.GATicketCategoryIds.includes(c.Id)));
    const totalGeneralAdmissionTickets = categoryGroupsInDate.sum((c: any) => c.Quantity);
    return (hasSeatingPlans ? linq.from(event.SeatingPlans).sum((s) => s.SeatCount) : 0) + totalGeneralAdmissionTickets;
  });

  var totalDiscountsAsInt = 0;
  const dayFormat = 'ddd Do MMM YY';

  const getEventDateTotals = (eventDateSummary: IEventDateSale) => {
    const eventDate: any = linq.from(event.Dates).firstOrDefault((ed) => ed.Id == eventDateSummary.EventDateId);
    if (!eventDate)
      return {
        total: 0,
        amount: '0/0',
        soldTickets: 0,
        totalTickets: 0,
        percentage: 0,
      };

    const categoryGroupsInDate = linq.from(event.UnallocatedCategoryGroups).where((g: any) => linq.from(g.Categories).any((c: any) => eventDate.GATicketCategoryIds.includes(c.Id)));
    const totalGeneralAdmissionTickets = categoryGroupsInDate.sum((c: any) => c.Quantity);

    var totalTicketsForEventDate = (hasSeatingPlans ? linq.from(event.SeatingPlans).sum((s) => s.SeatCount) : 0) + totalGeneralAdmissionTickets;
    var percentage = eventDateSummary.SoldTickets > totalTicketsForEventDate ? 100.0 : (eventDateSummary.SoldTickets / totalTicketsForEventDate) * 100.0;
    var amount = eventDateSummary.SoldTickets;
    var totalDiscounts = linq.from(eventDateSummary.Discounts).sum((t) => {
      return (t as any).Amount;
    });

    totalDiscountsAsInt += totalDiscounts;

    return {
      total: eventDateSummary.PriceAsInt - totalDiscounts,
      amount,
      soldTickets: eventDateSummary.SoldTickets,
      totalTickets: totalTicketsForEventDate,
      percentage,
    };
  };

  const getDateTimeNode = (eventDateSummary: IEventDateSale, timeOnly: boolean = false) => {
    var dateString = moment(eventDateSummary.EventDateAsString).format(dayFormat);
    var timeString = moment(eventDateSummary.EventDateAsString).format('h:mma');

    var children = [];
    const { total, amount, percentage } = getEventDateTotals(eventDateSummary);

    eventDateSummary.Discounts.forEach((discount) => {
      children.push(
        <EventSummary_TicketSalesReportRow
          key={'category_discount_row_' + eventDateSummary.EventDateId + '_' + discount.Id}
          title={discount.Name}
          // titleColour={'#41abbb'}
          subtitle={''}
          // subtitleColour={'#41abbb'}
          percentage={null}
          amount={null}
          total={'-' + CurrencyHelper.formatCurrency(props.currency, discount.Amount)}
        ></EventSummary_TicketSalesReportRow>,
      );
    });

    linq
      .from(eventDateSummary.TicketsByCategory)
      .where((tc) => tc.SeatCategory)
      .orderBy((tc) => (tc.SeatCategory ? tc.SeatCategory.Name : '') + ' ' + tc.Name)
      .toArray()
      .forEach((ticketCategory) => {
        children.push(
          <EventSummary_TicketSalesReportRow
            key={'category_row_' + eventDateSummary.EventDateId + '_' + ticketCategory.Id}
            subtitle={ticketCategory.Name}
            // subtitleColour={ticketCategory.Colour}
            title={ticketCategory.SeatCategory ? ticketCategory.SeatCategory.Name : ''}
            // titleColour={ticketCategory.SeatCategory ? ticketCategory.SeatCategory.Colour : ''}
            percentage={null}
            amount={ticketCategory.SeatCount}
            total={ticketCategory.PriceAsString}
            people={ticketCategory.People > 1 && ticketCategory.People != ticketCategory.SeatCount && ticketCategory.People}
          ></EventSummary_TicketSalesReportRow>,
        );
      });

    linq
      .from(eventDateSummary.TicketsByCategory)
      .where((tc) => !tc.SeatCategory)
      .orderBy((tc) => tc.Name)
      .toArray()
      .forEach((ticketCategory) => {
        children.push(
          <EventSummary_TicketSalesReportRow
            key={'category_row_' + eventDateSummary.EventDateId + '_' + ticketCategory.Id}
            title={ticketCategory.Name}
            // titleColour={ticketCategory.Colour}
            subtitle={ticketCategory.SeatCategory ? ticketCategory.SeatCategory.Name : ''}
            // subtitleColour={ticketCategory.SeatCategory ? ticketCategory.SeatCategory.Colour : ''}
            percentage={null}
            peopleWording={ticketCategory.Merchandise ? 'Item' : 'People'}
            wording={ticketCategory.Merchandise ? 'Merch Sale' : 'Ticket'}
            amount={ticketCategory.SeatCount}
            total={ticketCategory.PriceAsString}
            people={ticketCategory.People > 1 && ticketCategory.People != ticketCategory.SeatCount && ticketCategory.People}
          ></EventSummary_TicketSalesReportRow>,
        );
      });

    return (
      <EventSummary_TicketSalesReportRow
        bold={true}
        key={'date_row_' + eventDateSummary.EventDateId}
        child={children.length == 0 ? null : children}
        title={timeOnly ? timeString : dateString + ' at ' + timeString}
        subtitle={''}
        percentage={percentage}
        amount={amount}
        total={CurrencyHelper.formatCurrency(props.currency, total)}
      ></EventSummary_TicketSalesReportRow>
    );
  };

  linq
    .from(props.eventDateSales)
    .orderBy((g) => moment(g.EventDateAsString).unix())
    .forEach((group) => {
      rows.push(getDateTimeNode(group));
    });

  return (
    <div className="statement-page" style={{ borderRadius: '6px', overflow: 'none' }}>
      <div className="container-outer" style={{ margin: '0' }}>
        <div className="container-inner">
          <div className="section-to-print">
            <div className="summary">
              <img style={{ width: '275px', marginBottom: '30px' }} alt="Seaty.co.uk" src={SVGHelper.get('Seaty_LetsTalkTickets')} />

              <table style={{ width: '100%' }}>
                <tbody>
                  <tr>
                    <td style={{ width: '50%' }}>
                      <p>
                        <span style={{ fontWeight: 900 }}>Date generated</span>
                        <br />
                        {moment().format('dddd Do MMMM YYYY')}
                        <br />
                      </p>
                      <br />
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      <span style={{ fontWeight: 900 }}>Generated by</span>
                      <br />
                      {UserHelper.currentUser.Name}
                      <br />
                      <span style={{ fontWeight: 900 }}>Organisation</span>
                      <br />
                      {organisation.Name}
                      <br />
                    </td>
                  </tr>
                </tbody>
              </table>

              <br />
              <table style={{ width: '100%' }}>
                <tbody>
                  <tr>
                    <th style={{ width: '40%' }}></th>
                    <th style={{ width: '20%', textAlign: 'right' }}>Percentage</th>
                    <th style={{ width: '20%', textAlign: 'right' }}>Amount</th>
                    <th style={{ width: '20%', textAlign: 'right' }}>Price</th>
                  </tr>

                  {rows}

                  <tr style={{ fontWeight: 'bold' }}>
                    <td>Total</td>
                    <td style={{ textAlign: 'right' }}>{((totalSoldTickets / totalTickets) * 100.0).toFixed(1)}%</td>
                    <td style={{ textAlign: 'right' }}>{totalSoldTickets + '/' + totalTickets}</td>
                    <td style={{ textAlign: 'right' }}>
                      {CurrencyHelper.formatCurrency(
                        props.currency,
                        ticketsEnum.sum((t) => {
                          return (t as any).PriceAsInt;
                        }) - totalDiscountsAsInt,
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>

              <br />
              <br />

              <br />

              <p style={{ fontSize: '12px' }}>All orders in this report were placed in agreement with terms of service found at http://Seaty.co.uk/Docs/TermsOfService.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventSummary_TicketSalesReport;
