import linq from 'linq';
import moment from 'moment';
import { FunctionComponent, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useRecoilState } from 'recoil';
import AdminApi from '../../../../api/AdminApi';
import wizardEventState from '../../../../atoms/wizardEventState';
import newEventTourState from '../../../../atoms/wizardOrganisationState';
import Loader from '../../../../components/Loader';
import NumberHelper from '../../../../helpers/NumberHelper';
import StringHelper from '../../../../helpers/StringHelper';
import { IOrganisation } from '../../../../interfaces/IOrganisation';
import SVGToggleOff from '../../../../svg/SVGToggleOff';
import SVGToggleOn from '../../../../svg/SVGToggleOn';
import { AdminSection } from '../../AdminSections';
import './AdminSummary.scss';

export interface IProps {
  organisation: IOrganisation;
}

interface IEventOverview {
  OnlineFromDate: string;
  TimeZoneIana: string;
  EventId: number;
  EventName: string;
  CurrencySymbol: string;
  GroupName: string;
  CurrentTime?: string;
  CurrentTimeAsString?: string;
  SoldSeatedTicketsPercentage?: number;
  AvailableSeatedTickets: number;
  SoldSeatedTickets: number;
  SoldSeatedTicketsValue: number;
  SoldGATicketsPercentage?: number;
  AvailableGATickets: number;
  SoldGATickets: number;
  SoldGATicketsValue: number;
  ImageUrl: string;
  Organisation: IOrganisation;
  OrganisationId?: number;
  OrganisationName?: string;
  OrganisationTag?: string;
  OrganisationImageUrl?: string;
  VenueId?: number;
  VenueName: string;
  RequestsCount: number;
  AvailableTickets: number;
  SoldTickets: number;
  SoldTicketsValue: number;
  EventTag: string;
  Dates: any[];
  GeneralAdmission: boolean;
  EventDate?: string;
  EventDateAsString?: string;
  EventDateId?: number;
  TourId: number;
  TourName: string;
  TourTag: string;
}

const AdminOrganisationSummary: FunctionComponent<IProps> = ({ organisation }) => {
  const [busyMessage, setBusyMessage] = useState('Loading summary...');
  const [showEndedEvents, setShowEndedEvents] = useState(false);
  const [events, setEvents] = useState<IEventOverview[]>([]);
  const [, setNewEventTour] = useRecoilState(newEventTourState);
  const [, setNewEventOrganisation] = useRecoilState(wizardEventState);

  useEffect(() => {
    loadOverview(showEndedEvents);
  }, []);

  const loadOverview = (endedEvents: boolean) => {
    setBusyMessage('Loading summary...');
    AdminApi.request('GET', `/api/MyEvents?showOldEvents=${endedEvents}&organisationId=${organisation.Id}`)
      .then((eventDates: IEventOverview[]) => {
        eventDates = eventDates.map((r) => {
          return { ...r };
        });

        var groupedByOrganisationId = linq
          .from(eventDates)
          .groupBy((o) => o.OrganisationId)
          .selectMany((organisationGroup) => {
            return organisationGroup
              .groupBy((e) => e.EventId)
              .select((g) => {
                const event = g.first();
                const eventOverview: IEventOverview = {
                  ...event,
                  Organisation: {
                    Id: event.OrganisationId,
                    Name: event.OrganisationName,
                    Roles: [],
                    OrganisationTag: event.OrganisationTag,
                    LogoUrl: event.OrganisationImageUrl,
                    DisableTheme: true,
                    StreetAddress: '',
                    City: '',
                    Postcode: '',
                  },
                  AvailableSeatedTickets: g.sum((o) => o.AvailableSeatedTickets),
                  AvailableGATickets: g.sum((o) => o.AvailableGATickets),
                  RequestsCount: g.sum((o) => o.RequestsCount),
                  AvailableTickets: g.sum((o) => o.AvailableGATickets + o.AvailableSeatedTickets),
                  SoldSeatedTickets: g.sum((o) => o.SoldSeatedTickets),
                  SoldGATickets: g.sum((o) => o.SoldGATickets),
                  SoldSeatedTicketsValue: g.sum((o) => o.SoldSeatedTicketsValue),
                  SoldGATicketsValue: g.sum((o) => o.SoldGATicketsValue),
                  SoldTickets: g.sum((o) => o.SoldGATickets + o.SoldSeatedTickets),
                  SoldTicketsValue: g.sum((o) => o.SoldGATicketsValue + o.SoldSeatedTicketsValue),
                  Dates: g.orderBy((e) => moment.utc(e.EventDateAsString)).toArray(),
                  SoldMerchandise: g.sum((o) => o.SoldMerchandise),
                  SoldMerchandiseValue: g.sum((o) => o.SoldMerchandiseValue),
                  RemainingMerchandise: g.sum((o) => o.RemainingMerchandise),
                  AvailableMerchandise: g.sum((o) => o.AvailableMerchandise),
                };
                return eventOverview;
              })
              .toArray();
          })
          .orderByDescending((e) => (e.Dates.length == 0 ? '' : moment.utc(e.Dates[0].EventDateAsString)))
          .toArray();

        setEvents(groupedByOrganisationId);
        setBusyMessage(null);
      })
      .catch((message) => {
        alert(message);
        setBusyMessage(null);
      });
  };

  if (busyMessage) return <Loader inline>{busyMessage}</Loader>;

  if (events.length == 0) {
    return (
      <div>
        <div className="info">There are no active events for this organisation.</div>
      </div>
    );
  }

  var soldTickets = linq.from(events).sum((e: any) => e.SoldTickets);

  if (!NumberHelper.isNumeric(soldTickets)) soldTickets = 0;

  var availableTickets = linq.from(events).sum((e: any) => e.AvailableTickets);

  if (isNaN(availableTickets)) availableTickets = 0;

  var soldTicketsValue = linq.from(events).sum((e: any) => e.SoldTicketsValue);

  var soldPercentage = (soldTickets / availableTickets) * 100.0;
  if (isNaN(soldPercentage)) soldPercentage = 0;

  var soldMerchandise = linq.from(events).sum((e: any) => e.SoldMerchandise);

  if (!NumberHelper.isNumeric(soldMerchandise)) soldMerchandise = 0;

  var availableMerchandise = linq.from(events).sum((e: any) => e.AvailableMerchandise);

  if (isNaN(availableMerchandise)) availableMerchandise = 0;

  var soldMerchandiseValue = linq.from(events).sum((e: any) => e.SoldMerchandiseValue);

  var soldMercandisePercentage = (soldMerchandise / availableMerchandise) * 100.0;
  if (isNaN(soldMercandisePercentage)) soldMercandisePercentage = 0;

  return (
    <>
      <Helmet>
        <title>#{organisation.OrganisationTag} Summary</title>
        <meta name="description" content={`Summary for #${organisation.OrganisationTag}`} />
      </Helmet>

      <div className="toolbar">
        <div className="title">Organisation {AdminSection.Summary}</div>
        <div className="buttons">
          <button
            onClick={() => {
              var value = !showEndedEvents;

              setShowEndedEvents(value);

              loadOverview(value);
            }}
          >
            {!showEndedEvents ? <SVGToggleOff /> : <SVGToggleOn />}
            {showEndedEvents ? 'Exclude' : 'Include'} Inactive Events
          </button>
        </div>
      </div>

      <div className="row section">
        <div className="col-sm-7">
          <div className="fields">
            <h2>{showEndedEvents ? `Ticket Sales for All ${StringHelper.AddSWhenMany(events.length, 'Event')}` : `Ticket Sales for Active ${StringHelper.AddSWhenMany(events.length, 'Event')}`}</h2>

            <div className="field">
              <label className="inline">Tickets sold</label>
              <label className="right">{soldTickets}</label>
            </div>
            <div className="field">
              <label className="inline">{showEndedEvents ? 'Tickets sold in all events' : 'Tickets sold in active events'}</label>
              <label className="right">
                <span style={{ color: '#b2bbc3' }}>{events[0].CurrencySymbol}</span>
                <span>{(soldTicketsValue / 100.0).toFixed(2)}</span>
              </label>
            </div>
          </div>
          {soldMerchandise > 0 && (
            <div className="fields">
              <h2>
                {showEndedEvents
                  ? `Merchandise Sales for All ${StringHelper.AddSWhenMany(events.length, 'Event')}`
                  : `Merchandise Sales for Active ${StringHelper.AddSWhenMany(events.length, 'Event')}`}
              </h2>

              <div className="field">
                <label className="inline">Mercandise sold</label>
                <label className="right">{soldMerchandise}</label>
              </div>
              <div className="field">
                <label className="inline">{showEndedEvents ? 'Merchandise sold in all events' : 'Merchandise sold in active events'}</label>
                <label className="right">
                  <span style={{ color: '#b2bbc3' }}>{events[0].CurrencySymbol}</span>
                  <span>{(soldMerchandiseValue / 100.0).toFixed(2)}</span>
                </label>
              </div>
            </div>
          )}
        </div>

        <div className="col-sm-1"></div>
        <div className="col-sm-4">
          <div className="info">
            Here we have a summary of {showEndedEvents ? 'all' : 'active'} events for #{organisation.OrganisationTag}.
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminOrganisationSummary;
