import { FunctionComponent } from 'react';

export interface IProps {}

export default ((props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="48px" height="48px">
      <path d="M 23 2 C 18.385291 2 14.559689 5.5140788 14.058594 10 L 12 10 C 9.688 10 7.7634375 11.733203 7.5234375 14.033203 L 5.0234375 38.033203 C 4.8914375 39.298203 5.30425 40.566672 6.15625 41.513672 C 7.00825 42.458672 8.228 43 9.5 43 L 38.5 43 C 39.772 43 40.99175 42.458672 41.84375 41.513672 C 42.69575 40.567672 43.106609 39.298203 42.974609 38.033203 L 40.476562 14.033203 C 40.236562 11.733203 38.312 10 36 10 L 33.953125 10 C 33.984125 10.33 34 10.664 34 11 L 34 13 L 36 13 C 36.771 13 37.412187 13.578703 37.492188 14.345703 L 39.992188 38.345703 C 40.036187 38.767703 39.898234 39.189906 39.615234 39.503906 C 39.331234 39.818906 38.924 40 38.5 40 L 9.5 40 C 9.076 40 8.6697188 39.819859 8.3867188 39.505859 C 8.1027187 39.190859 7.9638125 38.767703 8.0078125 38.345703 L 10.507812 14.345703 C 10.587813 13.578703 11.229 13 12 13 L 14 13 L 14 16.5 A 1.50015 1.50015 0 1 0 17 16.5 L 17 11 C 17 7.6687195 19.668719 5 23 5 C 25.989762 5 28.439942 7.1510842 28.912109 10 L 19.132812 10 C 19.048812 10.32 19 10.654 19 11 L 19 13 L 29 13 L 29 16.5 A 1.50015 1.50015 0 1 0 32 16.5 L 32 11 C 32 6.0472805 27.952719 2 23 2 z M 21.037109 22.003906 C 19.945109 22.017906 18.924109 22.455328 18.162109 23.236328 C 17.399109 24.018328 16.987 25.048625 17 26.140625 C 17.014 27.232625 17.451281 28.253484 18.238281 29.021484 L 22.957031 33.580078 C 23.248031 33.861078 23.624 34.001953 24 34.001953 C 24.375 34.001953 24.751969 33.860078 25.042969 33.580078 L 29.767578 29.015625 C 30.549578 28.252625 30.987 27.232625 31 26.140625 C 31.013 25.049625 30.600891 24.017328 29.837891 23.236328 C 29.075891 22.454328 28.053891 22.016906 26.962891 22.003906 C 25.858891 21.963906 24.837641 22.401062 24.056641 23.164062 L 24 23.220703 L 23.943359 23.164062 C 23.162359 22.402062 22.175109 21.983906 21.037109 22.003906 z" />
    </svg>
  );
}) as FunctionComponent<IProps>;
