import { FunctionComponent, useEffect, useState } from 'react';
import { IEvent } from '../../../interfaces/IEvent';
import { ISeatingPlan } from '../../../interfaces/ISeatingPlan';
import Toolbar from '../toolbar/Toolbar';
import SVGPlus from '../../../svg/SVGPlus';
import linq from 'linq';
import NumberHelper from '../../../helpers/NumberHelper';
import { SeatingPlanSectionModal } from './SeatingPlanSectionModal';
import SeatingPlanHelper from '../../../helpers/SeatingPlanHelper';

export interface IProps {
  event?: IEvent;
  globalOptions: JSX.Element;
  onEventUpdated: (e: IEvent) => void;
}

const SeatingPlanSections: FunctionComponent<IProps> = (props) => {
  const { event } = props;
  const seatingPlans = event.SeatingPlans;
  const [selectedRow, setSelectedRow] = useState<ISeatingPlan>(null);
  const [dragOver, setDragOver] = useState<ISeatingPlan>(null);
  const [dragging, setDragging] = useState<ISeatingPlan>(null);
  const [draggedIndex, setDraggedIndex] = useState(null);

  const handleDragStart = (index, seatingPlan: ISeatingPlan) => (e) => {
    setDragging(seatingPlan);
    setDragOver(null);
    setDraggedIndex(index);
    e.dataTransfer.effectAllowed = 'move';
    e.dataTransfer.setData('text/html', null);
  };

  const handleDragOver = (index, seatingPlan: ISeatingPlan) => (e) => {
    e.preventDefault();
    if (draggedIndex !== null && draggedIndex !== index) {
      const currentRow = e.target.closest('tr');
      setDragOver(seatingPlan);
    }
  };

  const handleDragLeave = (e) => {
    e.target.classList.remove('drag-over');
    setDragOver(null);
  };

  const handleDrop = (index, seatingPlan) => (e) => {
    if (!dragging || !dragOver) return;

    e.preventDefault();
    e.target.classList.remove('drag-over');

    if (dragOver.Index != dragging.Index) {
      dragOver.ChangesMade = true;
      dragging.ChangesMade = true;

      if (dragOver.Index > dragging.Index) {
        dragging.Index = dragOver.Index + 0.5;
      } else if (dragOver.Index < dragging.Index) {
        dragging.Index = dragOver.Index - 0.5;
      }

      const newList = linq
        .from(seatingPlans)
        .orderBy((s) => s.Index)
        .toArray();
      newList.forEach((sp, index) => {
        sp.Index = index;
      });
      event.SeatingPlans = newList;

      props.onEventUpdated({ ...event });
    }

    setDragOver(null);
    setDraggedIndex(null);
    setDragging(null);
  };

  const addNew = () => {
    const sp = SeatingPlanHelper.getDefaultSeatingPlan();
    sp.ChangesMade = true;
    setSelectedRow(sp);
  };

  return (
    <>
      <Toolbar>
        <div className="options">
          {props.globalOptions}
          <button className="option" onClick={addNew}>
            <label>Add Section</label>
            <div className="icon">
              <SVGPlus />
            </div>
          </button>
        </div>
      </Toolbar>

      {seatingPlans?.length > 0 ? (
        <div className="orderoverview" style={{ paddingBottom: '100px' }}>
          <div className="container-outer" style={{ minHeight: '300px' }}>
            <div className="container-inner" style={{ backgroundColor: 'white' }}>
              <table className="table grid-table draggable">
                <thead>
                  <tr>
                    <th className="grid-header" style={{ width: '200px' }}></th>
                    <th className="grid-header">Name</th>
                  </tr>
                </thead>

                <tbody>
                  {linq
                    .from(seatingPlans)
                    .orderBy((s) => s.Index)
                    .toArray()
                    .map((seatingPlan: ISeatingPlan, index: number) => {
                      const id = seatingPlan.Id ? seatingPlan.Id : seatingPlan.Guid;
                      const className = `route${
                        dragOver && (dragOver.Id ? dragOver.Id : dragOver.Guid) == (seatingPlan.Id ? seatingPlan.Id : seatingPlan.Guid)
                          ? dragOver.Index > dragging.Index
                            ? ' place-down'
                            : ' place-up'
                          : ''
                      }`;

                      return (
                        <tr
                          className="route"
                          onClick={() => setSelectedRow(seatingPlan)}
                          style={{ display: 'table-row' }}
                          key={id}
                          draggable={true}
                          onDragStart={handleDragStart(index, seatingPlan)}
                          onDragOver={handleDragOver(index, seatingPlan)}
                          onDragLeave={handleDragLeave}
                          onDrop={handleDrop(index, seatingPlan)}
                        >
                          <td className={className}>
                            <div className={`status-tag tag-good`} style={{ marginLeft: '15px' }}>
                              {seatingPlan.Seats.length} Seats
                            </div>
                          </td>
                          <td className={className} style={{ fontWeight: 500 }}>
                            {!seatingPlan.Name || seatingPlan.Name.length == 0 ? 'No name' : seatingPlan.Name}
                            {seatingPlan.ChangesMade ? ' *' : ''}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      ) : (
        <div className="info">There are no results available.</div>
      )}

      {selectedRow && (
        <>
          <SeatingPlanSectionModal
            event={event}
            seatingPlan={selectedRow}
            onDelete={
              !selectedRow.HasOrders && event.SeatingPlans.length > 1
                ? () => {
                    event.SeatingPlans = linq
                      .from(event.SeatingPlans)
                      .where((sp) => (selectedRow.Id ? selectedRow.Id != sp.Id : selectedRow.Guid != sp.Guid))
                      .orderBy((sp) => sp.Index)
                      .toArray()
                      .map((sp, i) => {
                        sp.Index = i;
                        return sp;
                      });

                    props.onEventUpdated({ ...event });
                    setSelectedRow(null);
                  }
                : null
            }
            onClose={() => {
              setSelectedRow(null);
            }}
            onChange={(seatingPlan) => {
              const nextIndex =
                linq
                  .from(event.SeatingPlans)
                  .orderBy((sp) => sp.Index)
                  .last().Index + 1;

              if (!seatingPlan.Id) {
                if (!linq.from(event.SeatingPlans).any((sp) => sp.Guid == seatingPlan.Guid)) {
                  seatingPlan.Index = nextIndex;
                  event.SeatingPlans.push(seatingPlan);
                }
              }

              seatingPlan.ChangesMade = true;

              props.onEventUpdated({ ...event });
              setSelectedRow(null);
            }}
          />
        </>
      )}
    </>
  );
};

export default SeatingPlanSections;
