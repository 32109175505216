import linq from 'linq';
import React, { useEffect, useState } from 'react';
import Dropdown from '../../../components/Dropdown';
import InfoInput from '../../../components/InfoInput';
import InfoLabel from '../../../components/InfoLabel';
import GuidHelper from '../../../helpers/GuidHelper';
import NumberHelper from '../../../helpers/NumberHelper';
import { IEventDate } from '../../../interfaces/IEventDate';
import { ITicketCategory } from '../../../interfaces/ITicketCategory';
import moment from 'moment';
import SVGPlus from '../../../svg/SVGPlus';
import MerchandiseProductCategory from './MerchandiseProductCategory';
import { IDropDownItem } from '../../../components/DropdownItem';
import SVGMerchandise from '../../../svg/SVGMerchandise';

interface IProps {
  symbol: string;
  handleChange: () => void;
  eventDates: IEventDate[];
  index: number;
  group: any;
  onNoCategoriesRemaining: (group) => void;
  onAddCategoryToGroup: (group) => void;
  showColourPicker: (show) => void;
  disableDrag: boolean;
  setDraggingCategory: (category) => void;
}

const MerchandiseProduct: React.FC<IProps> = (props) => {
  var { group } = props;
  const [quantity, setQuantity] = useState(group.Quantity);
  const [error, setError] = useState(null);
  const [dragOver, setDragOver] = useState<ITicketCategory>(null);
  const [dragging, _setDragging] = useState<ITicketCategory>(null);
  const [draggedIndex, setDraggedIndex] = useState(null);
  const [showColourPicker, setShowColourPicker] = useState(false);

  const setDragging = (c) => {
    _setDragging(c);
    props.setDraggingCategory(c);
  };

  const getItems = () => {
    return linq
      .from(group.Categories)
      .orderBy((s) => s.Index)
      .toArray();
  };

  const resetIndexes = () => {
    getItems().forEach((i, index) => (i.Index = index));
    props.handleChange();
  };

  useEffect(() => {
    resetIndexes();
  }, []);

  const handleDragStart = (index, category: ITicketCategory) => (e) => {
    setDragging(category);
    setDragOver(null);
    setDraggedIndex(index);
    e.dataTransfer.effectAllowed = 'move';
    e.dataTransfer.setData('text/html', null);
  };

  const handleDragOver = (index, category: ITicketCategory) => (e) => {
    e.preventDefault();
    if (draggedIndex !== null && draggedIndex !== index) {
      setDragOver(category);
    }
  };

  const handleDragLeave = (e) => {
    e.target.classList.remove('drag-over');
    setDragOver(null);
  };

  const handleDrop = (e) => {
    if (!dragging || !dragOver) return;

    e.preventDefault();
    e.target.classList.remove('drag-over');

    if (dragOver.Index != dragging.Index) {
      // dragOver.ChangesMade = true;
      // dragging.ChangesMade = true;

      if (dragOver.Index > dragging.Index) {
        dragging.Index = dragOver.Index + 0.5;
      } else if (dragOver.Index < dragging.Index) {
        dragging.Index = dragOver.Index - 0.5;
      }

      const newList = getItems();
      newList.forEach((sp, index) => {
        sp.Index = index;
      });
      group.Categories = newList;
      props.handleChange();
    }

    setDragOver(null);
    setDraggedIndex(null);
    setDragging(null);
  };

  const handleChangeAndMarkSelectedDates = () => {
    group.SelectedEventDates.forEach((guid) => {
      const ed = linq.from(props.eventDates).firstOrDefault((ed) => ed.Guid == guid);
      if (ed) ed.ChangeMade = true;
    });

    props.handleChange();
  };

  const items = linq
    .from(props.eventDates)
    .orderBy((ed) => moment(ed.DateAsString).unix())
    .toArray()
    .map((d) => {
      return {
        groupText: moment.utc(d.Date).startOf('day').format('dddd Do MMMM YYYY'),
        group: moment.utc(d.Date).startOf('day').unix(),
        value: d.Guid,
        description: moment.utc(d.Date).format('h:mma'),
      };
    });

  return (
    <>
      <div className="fields" style={{ paddingTop: '25px' }}>
        <InfoLabel
          text={`${group.Categories.length > 1 ? 'Item Group' : 'Item'} Quantity & Dates`}
          tooltip={`Tell us how many of this item are available and on which dates. ${
            group.Categories.length > 1
              ? `This item group shares the quantity of items you have entered.`
              : `You can create a group of items that will share this quantity by clicking "Create item group".`
          } You can make items private, which will mean they can only be ordered by an organisation administrator.`}
        />
        <div>
          <div className="field" style={{ border: '0' }}>
            <div className="row">
              <div className="col-sm-4">
                <div className="input-label-group" style={{ paddingTop: 0 }}>
                  {
                    <Dropdown
                      multiple
                      description="Available dates"
                      items={
                        items.length > 2
                          ? [
                              {
                                value: 'all',
                                description: 'Toggle all',
                                group: null,
                                ignoreCount: true,
                              },
                              ...items,
                            ]
                          : items
                      }
                      selectAllGroupClicked={(items) => {
                        items.forEach((item) => {
                          const eventDate = props.eventDates.find((ed) => ed.Guid === item.value) || null;
                          eventDate.ChangeMade = true;

                          props.handleChange();
                        });
                      }}
                      onChange={(value: IDropDownItem[], item: IDropDownItem, e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
                        const isAllSelected = item && item.value === 'all';
                        const eventDatesMap = props.eventDates.map((ed) => ({ ...ed, ChangeMade: true }));

                        if (isAllSelected) {
                          group.SelectedEventDates = value.length - 1 === props.eventDates.length ? [] : eventDatesMap.map((ed) => ed.Guid);
                          props.handleChange();
                          return;
                        }

                        if (item) {
                          const eventDate = props.eventDates.find((ed) => ed.Guid === item.value) || null;
                          eventDate.ChangeMade = true;
                        }

                        group.SelectedEventDates = value.map((v) => v.value);

                        props.handleChange();
                      }}
                      value={group.SelectedEventDates}
                    />
                  }
                  <label className="input-label animated fadeIn">Available dates</label>
                </div>
              </div>
              <div className="col-sm-2">
                <InfoInput
                  labelText="Quantity"
                  onChange={(value) => {
                    setQuantity(value);
                    setError(null);

                    if (NumberHelper.isNumeric(value) && parseInt(value) > 0) {
                      var parsedValue = parseInt(value);
                      group.Quantity = parsedValue;
                      handleChangeAndMarkSelectedDates();
                    } else {
                      setError(`Quantity entered is not a valid number. If you save we will use the last valid value of ${group.Quantity} for this product.`);
                    }
                  }}
                  value={quantity}
                />
              </div>
              <div className="col-sm-6 flex-from-right" style={{ paddingTop: '4px' }}>
                <button className="admin-button icon-inline" onClick={() => props.onAddCategoryToGroup(props.group)}>
                  {group.Categories.length == 1 ? (
                    <>
                      <SVGMerchandise />
                      Create item group
                    </>
                  ) : (
                    <>
                      <SVGPlus />
                      Add item to group
                    </>
                  )}
                </button>
              </div>
            </div>

            {error && (
              <div className="info" style={{ marginBottom: '25px' }}>
                <strong>Attention:</strong> {error}
              </div>
            )}
          </div>

          <div className="field">
            <table className={props.disableDrag ? '' : 'draggable'}>
              <tbody>
                {getItems().map((category: ITicketCategory, index) => {
                  if (category.Guid == null) {
                    category.Guid = GuidHelper.new();
                  }
                  return (
                    <tr
                      key={'Item_' + category.Id + '_' + category.Guid}
                      draggable={!props.disableDrag && !showColourPicker && getItems().length > 1}
                      style={{ display: 'table-row' }}
                      onDragStart={props.disableDrag ? null : handleDragStart(index, category)}
                      onDragOver={props.disableDrag ? null : handleDragOver(index, category)}
                      onDragLeave={props.disableDrag ? null : handleDragLeave}
                      onDrop={props.disableDrag ? null : handleDrop}
                    >
                      <td
                        className={`${
                          props.disableDrag
                            ? ''
                            : dragOver && (dragOver.Id ? dragOver.Id : dragOver.Guid) == (category.Id ? category.Id : category.Guid)
                              ? dragOver.Index > dragging.Index
                                ? ' place-down'
                                : ' place-up'
                              : ''
                        }`}
                      >
                        <MerchandiseProductCategory
                          showColourPicker={(value) => {
                            setShowColourPicker(value);
                            props.showColourPicker(value);
                          }}
                          onPriceChange={(value: string) => {
                            if (NumberHelper.isNumeric(value)) {
                              var parsedValue = parseFloat(value);
                              category.PriceAsString = parsedValue.toFixed(2);
                              category.PriceAsInt = parseInt((parseFloat(parsedValue.toFixed(2)) * 100.0).toString());
                            } else {
                              category.PriceAsString = '';
                              category.PriceAsInt = 0;
                            }
                            handleChangeAndMarkSelectedDates();
                          }}
                          onHideChange={(value) => {
                            category.Hide = value;
                            handleChangeAndMarkSelectedDates();
                          }}
                          onNameChange={(value) => {
                            category.Name = value;
                            handleChangeAndMarkSelectedDates();
                          }}
                          onAdditionalInformationChange={(value) => {
                            category.Description = value;
                            handleChangeAndMarkSelectedDates();
                          }}
                          onColourChange={(value) => {
                            category.Colour = value;
                            handleChangeAndMarkSelectedDates();
                          }}
                          onPeopleChange={(value) => {
                            if (NumberHelper.isNumeric(value)) {
                              category.People = parseInt(value);
                              handleChangeAndMarkSelectedDates();
                            }
                          }}
                          onDeleteCategoryClick={() => {
                            props.group.Categories = props.group.Categories.filter((c) => c.Guid !== category.Guid);

                            if (props.group.Categories.length == 0) {
                              props.onNoCategoriesRemaining(group);
                            }
                            handleChangeAndMarkSelectedDates();
                          }}
                          symbol={props.symbol}
                          category={category}
                          index={index}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="spacer" style={{ margin: 0 }}></div>
    </>
  );
};

export default MerchandiseProduct;
